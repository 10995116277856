import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Async from 'react-async';
import Linkify from 'react-linkify';

import { Parser } from 'html-to-react';

import appStores from './app_stores.png';
import logo from './logo.png';
import icone from './icone.png';

import './App.css';

import awsconfig from './aws-exports';

import { API } from 'aws-amplify';

// configure api backend
API.configure(awsconfig);

const parser = new Parser();

const load = urlSuffix => {
  return () =>
    new Promise((res, rej) => {
      API.get('api96afbf16', `/sms/${urlSuffix}`)
        .then(response => {
          res(response);
        })
        .catch(err => {
          rej();
        });
    });
};

const text2HTML = text => {
  // 1: Plain Text Search
  text = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');

  // 2: Line Breaks
  text = text.replace(/\r\n?|\n/g, '</p><p>');

  // 3: Paragraphs
  text = text.replace(/<br>\s*<br>/g, '</p><p>');

  // 4: Wrap in Paragraph Tags
  text = '<p>' + text + '</p>';

  return text;
};

const Anexo = props => (
  <a
    className="Anexo"
    style={{
      padding: 10,
      margin: 6,
      borderRadius: 4,
      backgroundColor: '#643493',
      color: '#F8ED49',
      textDecoration: 'none',
      fontWeight: 'bold',
      display: 'inline-block',
    }}
    key={props.key}
    download={props.item.titulo}
    target="_blank"
    rel="noopener noreferrer"
    href={props.item.link}
  >
    {props.item.abreviacao}
  </a>
);

const Anexos = props => (
  <p className="Anexos">
    <b>Clique nos botões para baixar os anexos</b>
    <br />
    <br />
    {props.anexos.map((item, i) => (
      <Anexo key={i} item={item} />
    ))}
  </p>
);

const Header = props => (
  <div className="Header">
    <img
      style={{
        width: 40,
        height: 45,
      }}
      src={icone}
      alt="EducareBox"
    />
  </div>
);

const BaixeOApp = props => (
  <p className="Baixe-o-app">
    <b
      style={{
        fontSize: '11pt',
      }}
    >
      Para ter informações em tempo real, baixe o aplicativo!
    </b>
    <br />
    <br />
    <a href="http://onelink.to/educarebox" target="_blank" rel="noopener noreferrer">
      <img
        style={{
          width: 160,
        }}
        src={appStores}
        alt="Baixe o app"
      />
    </a>
  </p>
);

const Footer = props => (
  <p className="Footer">
    <img
      style={{
        width: 100,
      }}
      src={logo}
      alt="EducareBox"
    />
  </p>
);

class Message extends Component {
  render() {
    return (
      <div style={{ overflowWrap: 'break-word' }}>
        <Header />
        <Linkify>{parser.parse(text2HTML(this.props.response.message))}</Linkify>
        {this.props.response.anexos ? <Anexos anexos={this.props.response.anexos} /> : ''}
        <BaixeOApp />
        <Footer />
      </div>
    );
  }
  componentDidMount() {
    const anchors = document.getElementsByTagName('a');
    for (var i = 0; i < anchors.length; i++) {
      anchors[i].setAttribute('target', '_blank');
    }
  }
}

const Loading = props => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 400,
    }}
  >
    <img
      style={{
        width: 80,
        height: 89,
      }}
      src={icone}
      className="App-logo"
      alt="logo"
    />
  </div>
);

class Child extends Component {
  render() {
    const { urlSuffix } = this.props.match.params;
    return (
      <Async promiseFn={load(urlSuffix)}>
        <Async.Loading>
          <Loading />
        </Async.Loading>
        <Async.Resolved>{response => <Message response={response} />}</Async.Resolved>
        <Async.Rejected>Aconteceu algo inesperado!!</Async.Rejected>
      </Async>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/:urlSuffix" component={Child} />
      </Router>
    );
  }
}

export default App;
