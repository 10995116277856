// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api96afbf16",
            "endpoint": "https://4mlk6dgk30.execute-api.us-east-1.amazonaws.com/stage",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "sms-link-20190613203106-hostingbucket-stage",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1xmsqik128hqs.cloudfront.net"
};


export default awsmobile;
